<template>
  <GlPageWrap
    hide-title-on-mobile
    title="Scoring Profiles"
  >
    <template #actions>
      <div class="flex m-display-block m-fullwidth">
        <GlCoinSelect
          v-if="!config.VUE_APP_MULTI_LABELING"
          @change="loadData"
        />
        <button
          class="gl-button gl-button--dark gl-button--padder m-fullwidth m-mt-3"
          :disabled="profilesLoading"
          @click="handleCreateProfile"
        >
          Create profile
        </button>
      </div>
    </template>
    <div class="container pa-3 mb-4 m-mb-2">
      <div
        v-if="profilesLoading"
        class="full-width flex justify-center"
      >
        <GlLoader />
      </div>
      <div
        v-else
        class="flex scoring-profile-cards__wrapper"
      >
        <ProfileCard
          v-for="(profile, index) in profilesList"
          :id="profile.id"
          :key="profile.id"
          ref="profileCard"
          :active-status="profile.active"
          :class="[{'scoring-profile-card__selected': selectedProfileId === profile.id}, {'mr-3': index !== profilesList.length - 1}]"
          :is-edit="profile.isEdit"
          :profile-name="profile.name"
          @ctx-open="ctxOpenHandler"
          @select="selectProfile($event, index)"
          @update="updateActiveStateProfile($event)"
        />
        <ContextMenu
          ref="ctx"
          v-model="showContextMenu"
          :data="ctxProfileData"
          :position="contextMenuPosition"
          @delete-activate="deleteActivate"
          @edit-activate="editActivate"
        />
      </div>
    </div>
    <div class="scoring flex t-display-block fullwidth">
      <TypesScoring
        v-if="selectedProfileId !== undefined && !profilesLoading"
        :can-edit-score="Boolean(selectedProfileId)"
        :can-reset-score="Boolean(selectedProfileId)"
        class="mr-4 t-mr-0"
        :profile-id="selectedProfileId"
        with-def-score
        @loading="loading = $event"
      />
      <TagsScoring
        v-if="selectedProfileId !== undefined && !profilesLoading"
        :can-edit-score="Boolean(selectedProfileId)"
        :can-reset-score="Boolean(selectedProfileId)"
        :profile-id="selectedProfileId"
        with-def-score
        @loading="loading = $event"
      />
    </div>
    <CreateProfileModal
      v-if="creatingProfile"
      v-model="creatingProfile"
      :loading="loading"
      @add="handleSaveProfile"
      @close="creatingProfile = false"
    />
    <EditProfileModal
      v-if="editingProfile"
      v-model="editingProfile"
      :data="ctxProfileData"
      @close="editingProfile = false"
      @edit="handleEditProfile"
    />
    <deleteProfileModal
      v-if="deletedProfile"
      v-model="deletedProfile"
      :data="ctxProfileData"
      @close="deletedProfile = false"
      @delete="handleDeleteProfile"
    />
    <ConfirmUpdateCalcModal
      v-if="confirmRecalc"
      :id="preSelectedProfileId"
      v-model="confirmRecalc"
      @close="handleCloseConfirmRecalcModal"
      @confirm="handleRecalcProfile"
    />
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions, mapState } from "vuex";
// Components
import GlLoader from "@/components/gl-loader.vue";
import GlCoinSelect from "@/components/gl-coin-select.vue";
import GlPageWrap from "@/components/layout/gl-page-wrap.vue";
import ContextMenu from "@/pages/scoring-profiles/components/ContextMenu.vue";
import ProfileCard from "@/pages/scoring-profiles/components/ProfileCard.vue";
import TagsScoring from "@/pages/scoring-profiles/components/TagsScoring.vue";
import TypesScoring from "@/pages/scoring-profiles/components/TypesScoring.vue";
import EditProfileModal from "@/pages/scoring-profiles/modals/editProfileModal.vue";
import deleteProfileModal from "@/pages/scoring-profiles/modals/deleteProfileModal.vue";
import CreateProfileModal from "@/pages/scoring-profiles/modals/createProfileModal.vue";
// Configs
import config from "@/utils/appConfig";
import ConfirmUpdateCalcModal from "@/pages/scoring-profiles/modals/confirmUpdateCalcModal.vue";

export default {
  components: {
    GlLoader,
    GlPageWrap,
    ContextMenu,
    ProfileCard,
    TagsScoring,
    GlCoinSelect,
    TypesScoring,
    EditProfileModal,
    CreateProfileModal,
    deleteProfileModal,
    ConfirmUpdateCalcModal,
  },
  data() {
    return {
      selectedProfileId: undefined,
      preSelectedProfileId: undefined,
      ctxProfileData: null,
      loading: false,
      profilesLoading: false,
      creatingProfile: false,
      editingProfile: false,
      deletedProfile: false,
      showContextMenu: false,
      confirmRecalc: false,
      contextMenuPosition: { x: 0, y: 0 },
      profilesList: []
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    config() {
      return config
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('scoringProfiles', ['getProfilesList', 'createProfile', 'activateProfile', 'deleteProfile', 'editProfile']),
    ...mapActions('user', ['getMe']),
    editActivate(val) {
      this.ctxProfileData = val
      this.editingProfile = true
    },
    deleteActivate(val) {
      this.ctxProfileData = val
      this.deletedProfile = true
    },
    async loadData() {
      this.getProfilesList().then(({ data, success }) => {
        if (success) {
          this.profilesList = data || []
          this.selectedProfileId = this.profilesList.find(pr => pr.active).id
        } else {
          this.profilesList = []
          this.selectedProfileId = null
        }
      }).finally(() => {
        this.profilesLoading = false
      })
    },
    ctxOpenHandler(val) {
      this.showContextMenu = false
      this.contextMenuPosition = val.position
      this.ctxProfileData = this.profilesList.find(pr => pr.id === val.id) || null
      this.showContextMenu = true
    },
    handleCreateProfile() {
      this.creatingProfile = true
    },
    handleSaveProfile(name) {
      this.loading = true
      this.createProfile(name).then(({ data, success }) => {
        if (success) {
          this.creatingProfile = false
          this.loadData()
        } else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      }).catch(({ response: { data } }) => {
        this.loading = false
        this.$toasted.global.error({message: `${data.data.message}`})
      }).finally(() => {
        this.loading = false
      })
    },
    handleEditProfile(profileData) {
      this.editProfile({ body: profileData }).then(({ data, success }) => {
        if (success) {
          this.editingProfile = false
          this.loadData()
        } else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({message: `${data.data.message}`})
      })
    },
    handleDeleteProfile({ id }) {
      this.deleteProfile(id).then(({ data, success }) => {
        if (success) {
          this.deletedProfile = false
          this.loadData()
        } else {
          this.$toasted.global.error({message: `${data.message}`})
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({message: `${data.data.message}`})
      })
    },
    selectProfile(id) {
      this.selectedProfileId = id
    },
    async handleCloseConfirmRecalcModal(id) {
      this.confirmRecalc = false

      const index = this.profilesList.findIndex(pr => pr.id === id)

      if (index !== -1) {
        this.profilesList.splice(index, 1, { ...this.profilesList[index], active: false })
        this.$refs.profileCard[index].active = false
      }
    },
    handleRecalcProfile() {
      this.confirmRecalc = false
      this.updateActiveStateProfile(this.selectedProfileId, true)
    },
    updateActiveStateProfile(id, recalculated = false) {
      this.confirmRecalc = true
      this.preSelectedProfileId = id

      if (recalculated) {
        this.profilesLoading = true
        this.activateProfile({ id }).then(({ success, data }) => {

          this.loadData()

          if (recalculated) this.confirmRecalc = false

          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
          }
        }).finally(() => {
          this.profilesLoading = false
        })
      }
    },
  },
}
</script>

<style>
.scoring-profile-cards__wrapper {
  max-width: 100%;
  overflow: auto;
  display: flex;
}
@media (max-width: 1200px) {
  .tags-scoring + .tags-scoring {
    margin-top: 24px;
  }
}

@media (max-width: 767px) {
  .tags-scoring + .tags-scoring {
    margin-top: 8px;
  }
}
</style>
